<template>
  <div class="oauth">
    <!-- 已登录 -->
    <div class="logined" v-loading="loading" v-if="is_login">
      <div class="pc">
        <div class="header">
          <img src="@image/banneruympc.png" alt="" />
        </div>
        <div class="main">
          <div class="tm">
            <img src="@image/uymtm.png" alt="" />
          </div>
          <div class="content">
            <div class="left">
              <div class="desc">
                <p>
                  授权<span style="color: #1cdac6">网医</span>使用个人信息
                  (姓名、科室、职称、医院等) 协助注册<span
                    style="color: #e69615"
                    >优医迈</span
                  >
                </p>
                <p style="margin-top: 10px">
                  点击授权后您将离开<span style="color: #1cdac6">网医</span
                  >平台去往<span style="color: #e69615">优医迈</span>网站
                </p>
              </div>
              <div class="button">
                <el-button @click.native="agreeLogin" type="primary"
                  >同意授权</el-button
                >
                <!-- <el-button @click.native="cancelLogin" type="info"
                  >取消</el-button
                > -->
              </div>
            </div>
            <div class="right">
              <div class="brief">
                <p class="uym"><img src="@image/uymlogo.png" alt="" /></p>
                <div class="word">
                  <span style="color: #1cdac6">网医</span
                  >向您推荐为医疗卫生专业人士免费提供的中立医学资讯平台-<span
                    style="color: #e69615"
                    >优医迈</span
                  >。<span style="color: #e69615">优医迈</span
                  >已为超过70万中国医生及药师提供医学资讯、研究进展、文献工具、继教资源等医学内容。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile">
        <div class="top">
          <div class="header">授权登录页面</div>

          <div class="brief">
            <p class="uym"><img src="@image/uymlogo.png" alt="" /></p>
            <div class="word">
              <p>
                <span style="color: #1cdac6">网医</span
                >向您推荐为医疗卫生专业人士免费提供的中立医学资讯平台-<span
                  style="color: #e69615"
                  >优医迈</span
                >。<span style="color: #e69615">优医迈</span
                >已为超过70万中国医生及药师提供医学资讯、研究进展、文献工具、继教资源等医学内容。
              </p>
              <p style="margin-top: 10px">
                点击授权后您将离开<span style="color: #1cdac6">网医</span
                >平台去往<span style="color: #e69615">优医迈</span>网站。
              </p>
            </div>
          </div>
        </div>

        <div class="bottom">
          <div class="desc">
            授权<span style="color: #1cdac6">网医</span>使用个人信息
            (姓名、科室、职称、医院等) 协助注册<span style="color: #e69615"
              >优医迈</span
            >
          </div>
          <div class="button">
            <el-button @click.native="agreeLogin" type="primary"
              >同意授权</el-button
            >
            <!-- <el-button @click.native="cancelLogin" type="info">取消</el-button> -->
          </div>
        </div>
      </div>
    </div>

    <!-- 未登录 -->
    <div class="nologin" v-loading="loading" v-else>
      <div class="pc">
        <div class="header">
          <img src="@image/banneruympc.png" alt="" />
        </div>
        <div class="main">
          <div class="tm">
            <img src="@image/uymtm.png" alt="" />
          </div>
          <div class="content">
            <div class="left">
              <div class="input_warp">
                <el-input
                  v-model="account"
                  placeholder="请输入账号"
                  autocomplete="off"
                ></el-input>
                <el-input
                  v-model="pwd"
                  placeholder="请输入密码"
                  show-password
                  autocomplete="off"
                ></el-input>
              </div>
              <div class="desc">
                <p>
                  授权<span style="color: #1cdac6">网医</span>使用个人信息
                  (姓名、科室、职称、医院等) 协助注册<span
                    style="color: #e69615"
                    >优医迈</span
                  >
                </p>
                <p style="margin-top: 10px">
                  点击授权后您将离开<span style="color: #1cdac6">网医</span
                  >平台去往<span style="color: #e69615">优医迈</span>网站
                </p>
              </div>
              <div class="button">
                <el-button @click.native="authLogin" type="primary"
                  >同意授权</el-button
                >
                <!-- <el-button @click.native="cancelLogin" type="info"
                  >取消</el-button
                > -->
              </div>
            </div>
            <div class="right">
              <div class="brief">
                <p class="uym"><img src="@image/uymlogo.png" alt="" /></p>
                <div class="word">
                  <span style="color: #1cdac6">网医</span
                  >向您推荐为医疗卫生专业人士免费提供的中立医学资讯平台-<span
                    style="color: #e69615"
                    >优医迈</span
                  >。<span style="color: #e69615">优医迈</span
                  >已为超过70万中国医生及药师提供医学资讯、研究进展、文献工具、继教资源等医学内容。
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="mobile">
        <div class="top">
          <div class="header">授权登录页面</div>

          <div class="brief">
            <p class="uym"><img src="@image/uymlogo.png" alt="" /></p>
            <div class="word">
              <p>
                <span style="color: #1cdac6">网医</span
                >向您推荐为医疗卫生专业人士免费提供的中立医学资讯平台-<span
                  style="color: #e69615"
                  >优医迈</span
                >。<span style="color: #e69615">优医迈</span
                >已为超过70万中国医生及药师提供医学资讯、研究进展、文献工具、继教资源等医学内容。
              </p>
              <p style="margin-top: 10px">
                点击授权后您将离开<span style="color: #1cdac6">网医</span
                >平台去往<span style="color: #e69615">优医迈</span>网站。
              </p>
            </div>
          </div>
        </div>

        <div class="bottom">
          <div class="input_warp">
            <el-input
              v-model="account"
              placeholder="请输入账号"
              autocomplete="off"
            ></el-input>
            <el-input
              v-model="pwd"
              placeholder="请输入密码"
              show-password
              autocomplete="off"
            ></el-input>
          </div>

          <div class="desc">
            授权<span style="color: #1cdac6">网医</span>使用个人信息
            (姓名、科室、职称、医院等) 协助注册<span style="color: #e69615"
              >优医迈</span
            >
          </div>
          <el-button @click.native="authLogin" type="primary"
            >同意授权</el-button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      is_login: "",
      account: "",
      pwd: "",
      client_id: "",
      redirect_uri: "",
      loading: true,
      drsay_key: "",
      member_uid: "",
      weixin_uid: "",
    };
  },

  created() {
    this.is_login = localStorage.getItem("token");
    // 根据链接判断
    if (this.$route.query) {
      //  判断是否含有client_id和redirect_uri
      if (!this.$route.query.client_id || !this.$route.query.redirect_uri) {
        this.$message({
          type: "error",
          message: "链接错误",
          duration: 1000,
        });
        setTimeout(() => {
          this.$router.push("/index");
        }, 1000);
        return;
      }
      this.client_id = this.$route.query.client_id;
      this.redirect_uri = decodeURI(this.$route.query.redirect_uri);

      // 判断是否在登录状态
      if (this.redirect_uri.includes("token")) {
        var index = this.redirect_uri.lastIndexOf("=");
        this.is_login = this.redirect_uri.substring(
          index + 1,
          this.redirect_uri.length
        );
      }

      // 上医说登录
      if (this.redirect_uri.includes("drsay_key")) {
        var index = this.redirect_uri.lastIndexOf("=");
        this.drsay_key = this.redirect_uri.substring(
          index + 1,
          this.redirect_uri.length
        );
        this.drsayUser();
      }

      // 微信登录
      if (this.redirect_uri.includes("weixin_uid")) {
        this.is_login = true;
        var index = this.redirect_uri.lastIndexOf("=");
        this.weixin_uid = this.redirect_uri.substring(
          index + 1,
          this.redirect_uri.length
        );
      }
    }
  },

  mounted() {
    this.loading = false;
  },

  methods: {
    // 未登录状态下授权登录
    async authLogin() {
      if (!this.account) {
        this.$message("请输入账号");
        return;
      }
      if (!this.pwd) {
        this.$message("请输入密码");
        return;
      }
      let res = await this.$loginApi.authLogin({
        account: this.account.trim(),
        pwd: this.pwd.trim(),
        client_id: this.client_id,
        redirect_uri: this.redirect_uri,
      });
      if (res.result_code == 200) {
        window.location.href = res.data;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    // 已登录状态下授权登录
    async agreeLogin() {
      let loginData = {};
      // token登录
      if (typeof this.is_login == "string") {
        loginData = {
          token: this.is_login,
          client_id: this.client_id,
          redirect_uri: this.redirect_uri,
        };
      }
      // 上医说登录
      if (this.member_uid) {
        loginData = {
          client_id: this.client_id,
          redirect_uri: this.redirect_uri,
          member_uid: this.member_uid,
        };
      }

      // 微信登录
      if (this.weixin_uid) {
        loginData = {
          client_id: this.client_id,
          redirect_uri: this.redirect_uri,
          weixin_uid: this.weixin_uid,
        };
      }

      let res = await this.$loginApi.authLogin(loginData);
      if (res.result_code == 200) {
        window.location.href = res.data;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },

    // 获取上医说医生
    async drsayUser() {
      this.loading = true;
      let res = await this.$loginApi.drsayUser({
        drsay_key: this.drsay_key,
      });
      if (res.result_code == 200) {
        this.loading = false;
        this.member_uid = res.data;
        this.is_login = true;
      } else {
        this.$message({
          type: "error",
          message: res.message,
        });
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@assets/css/themeColor.scss";
div,
p,
span {
  box-sizing: border-box;
}
// pc端
@media screen and (min-width: 750px) {
  .oauth {
    .logined {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      background: #f4f5fa;
      > .mobile {
        display: none;
      }

      > .pc {
        height: 100%;
        > .header {
          > img {
            width: 100%;
          }
        }
        > .main {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 70%;
          height: 70%;
          background: #fff;

          > .tm {
            position: absolute;
            left: 0;
            top: 0;
            > img {
              width: 100%;
            }
          }

          > .content {
            position: relative;
            padding: 40px 50px;
            height: 100%;
            display: flex;
            align-items: center;
            &:after {
              content: "";
              position: absolute;
              width: 1px;
              height: 80%;
              left: 50%;
              top: 50%;
              background: rgb(146, 144, 144);
              transform: translate(-50%, -50%);
            }

            > .left {
              width: 50%;
              padding: 0 50px;

              > .desc {
                font-size: 14px;
                margin: 25px 0;
                line-height: 25px;
                color: #333;
              }

              > .button {
                margin-top: 30px;
                text-align: left;

                > button {
                  width: 100%;
                  font-size: 20px;
                  margin-bottom: 20px;
                  margin-left: 0;
                }
              }
            }
            > .right {
              width: 50%;
              color: #333;
              > .brief {
                font-size: 14px;
                line-height: 28px;

                > .uym {
                  img {
                    width: 100%;
                    margin-bottom: 30px;
                  }
                }
                > .word {
                  padding: 0 50px;
                }
              }
            }
          }
        }
      }
    }

    .nologin {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: auto;
      background: #f4f5fa;

      > .mobile {
        display: none;
      }

      > .pc {
        height: 100%;
        > .header {
          > img {
            width: 100%;
          }
        }

        > .main {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 70%;
          height: 70%;
          background: #fff;

          > .tm {
            position: absolute;
            left: 0;
            top: 0;
            > img {
              width: 100%;
            }
          }

          > .content {
            position: relative;
            padding: 40px 50px;
            height: 100%;
            display: flex;
            align-items: center;
            &:after {
              content: "";
              position: absolute;
              width: 1px;
              height: 80%;
              left: 50%;
              top: 50%;
              background: rgb(146, 144, 144);
              transform: translate(-50%, -50%);
            }

            > .left {
              width: 50%;
              padding: 0 50px;
              > .input_warp {
                > .el-input {
                  margin-bottom: 10px;
                  /deep/ .el-input__inner {
                    height: 40px;
                    font-size: 16px;
                  }
                }
              }

              > .desc {
                font-size: 14px;
                margin: 25px 0;
                line-height: 25px;
                color: #333;
              }

              > .button {
                margin-top: 30px;
                text-align: left;

                > button {
                  width: 100%;
                  font-size: 20px;
                  margin-bottom: 20px;
                  margin-left: 0;
                }
              }
            }

            > .right {
              width: 50%;
              color: #333;
              > .brief {
                font-size: 14px;
                line-height: 28px;

                > .uym {
                  img {
                    width: 100%;
                    margin-bottom: 30px;
                  }
                }

                > .word {
                  padding: 0 50px;
                }
              }
            }
          }
        }
      }
    }
  }
}

// 移动端
@media screen and (max-width: 750px) {
  .oauth {
    .logined {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: auto;

      > .pc {
        display: none;
      }

      > .mobile {
        > .top {
          > .header {
            width: 100%;
            color: #fff;
            text-align: center;
            height: 60px;
            line-height: 60px;
            font-size: 18px;
            background: #6fb8c0;
          }

          > .brief {
            line-height: 28px;

            > .uym {
              padding: 50px 0 30px;
              img {
                width: 100%;
              }
            }

            > .word {
              padding: 0 30px;
              font-size: 14px;
            }
          }
        }

        > .bottom {
          padding: 50px 20px;

          > .desc {
            margin: 25px 0;
            line-height: 25px;
            padding: 0 10px;
            color: #333;
            font-size: 14px;
          }

          > .button {
            margin-top: 30px;
            text-align: left;

            > button {
              width: 100%;
              font-size: 20px;
              margin-bottom: 20px;
              margin-left: 0;
            }
          }
        }
      }
    }

    .nologin {
      position: fixed;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      overflow: auto;

      > .pc {
        display: none;
      }

      > .mobile {
        > .top {
          > .header {
            width: 100%;
            color: #fff;
            text-align: center;
            height: 60px;
            line-height: 60px;
            font-size: 18px;
            background: #6fb8c0;
          }

          > .brief {
            line-height: 28px;

            > .uym {
              padding: 50px 0 30px;
              img {
                width: 100%;
              }
            }

            > .word {
              padding: 0 30px;
              font-size: 14px;
            }
          }
        }

        > .bottom {
          padding: 50px 20px;

          > .input_warp {
            padding: 0 20px;
            > .el-input {
              margin-bottom: 20px;
              /deep/ .el-input__inner {
                height: 50px;
                font-size: 20px;
              }
            }
          }

          > .desc {
            margin: 25px 0;
            padding: 0 10px;
            line-height: 25px;
            color: #333;
          }

          button {
            font-size: 20px;
            width: 100%;
            height: 50px;
          }
        }
      }
    }
  }
}
</style>

<style>
main > footer > .statement {
  display: none !important;
}
</style>